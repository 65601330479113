import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import * as React from 'react';
import { TextInputGroup } from './text-input-group';

export type mailingData = {
    name: string;
    email: string;
};

export type inputGroup = {
    labelText?: string;
    validationText?: string;
    placeholder?: string;
};

export interface IMailingListInputGroup {
    submitText?: string;
    name: inputGroup;
    email: inputGroup;
    context: ICoreContext<IGeneric<IAny>>;
    onFinish(dataSet: mailingData): void;
}

export interface ISubmittingState {
    isSubmitting?: boolean;
}

type mailingListFormStructureGroup = {
    validity: boolean;
    value: string;
    externalGroup?: inputGroup;
    regex?: RegExp;
    maxLength?: number;
};

type mailingListFormStructure = {
    [fieldName: string]: mailingListFormStructureGroup;
};

/**
 * Constructs only the necessary components of the mailing list inputs
 *
 * To add a new field, add it to the internal form structure object and
 * the external props typing
 */
export class MailingListInputGroup extends React.Component<IMailingListInputGroup, ISubmittingState> {

    private _formStructure: mailingListFormStructure = {
        name: {
            validity: false,
            value: ''
        },
        email: {
            validity: false,
            value: '',
            // eslint-disable-next-line security/detect-unsafe-regex
            regex: /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]+(?:-+[a-z0-9]+)*\.)+(?:xn--[a-z0-9]+|[a-z]{2,16})$/i
        }
    };
    constructor(props: IMailingListInputGroup) {
        super(props);
        this.state = {
            isSubmitting: false
        };
        this._handleValidityUpdate = this._handleValidityUpdate.bind(this);
        this._handleValueFinish = this._handleValueFinish.bind(this);
        this._handleFormExport = this._handleFormExport.bind(this);
    }

    public render(): JSX.Element {
        this._setExternalGroups();
        return (
            <React.Fragment>
                {Object.keys(this._formStructure).map(fieldName => {
                    const field = this._formStructure[fieldName];
                    return (
                        <TextInputGroup
                            key={fieldName}
                            label={field.externalGroup!.labelText}
                            id={fieldName}
                            maxLength={field.maxLength}
                            onChange={this._handleValueFinish}
                            isValid={this._handleValidityUpdate}
                        />
                    );
                })}
                <button
                    className='modal-email-subscription__button'
                    onClick={this._handleFormExport}
                    disabled={this.state.isSubmitting}
                >
                    {this.props.submitText || 'Submit'}
                </button>
            </React.Fragment>
        );
    }

    private _setExternalGroups(): void {
        this._formStructure.name.externalGroup = this.props.name;
        this._formStructure.email.externalGroup = this.props.email;
    }
    private _handleFormExport(event: React.MouseEvent): void {
        this.props.onFinish({
            email: this._formStructure.email.value,
            name: this._formStructure.name.value
        });
        this.setState({ // disabled button to prevent duplicates
            isSubmitting: true
        });
        event.preventDefault();
    }

    private _handleValueFinish(value: string, isValid: boolean, id: string): void {
        const fieldData = this._formStructure[id];
        fieldData.validity = isValid;
        fieldData.value = value;

        if (isValid) {
            this.setState({ // enable button when email is valid
                isSubmitting: false
            });
        }

        // re-render, no reason to use mobx for this
        this.setState({});
    }

    private _handleValidityUpdate(value: string, id: string): string | void {
        const fieldData = this._formStructure[id];

        // should always be true but just in case
        if (fieldData) {
            const validationText = fieldData.externalGroup!.validationText;
            if (value.length === 0 && id !== 'name') {
                return validationText;
            }
            if (value.length > 0 && fieldData.regex && !fieldData.regex.test(value)) {
                return validationText;
            }
        }
    }
}
