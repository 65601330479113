import classnames from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
interface ITextInputGroupProps {
    useDatePicker?: boolean;
    label?: string;
    placeholder?: string;
    id: string;
    initialValue?: string;
    maxLength?: number;
    htmlProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    isValid(curValue: string, id: string): string | void;
    onChange(value: string, currentValidity: boolean, id: string): void;
}

/**
 * TextInputGroup is a wrapper that allows you to validate text values from the
 * user and returns the correct value after
 */
@observer
export class TextInputGroup extends React.Component<ITextInputGroupProps> {
    @observable private _internalValue: string = '';
    @observable private _errorMessage: string = '';
    @observable private _isFirstBlur: boolean = true;

    constructor(props: ITextInputGroupProps) {
        super(props);

        if (props.initialValue) {
            this._internalValue = props.initialValue;
        }

        this._valueChanged = this._valueChanged.bind(this);
        this._blurred = this._blurred.bind(this);
    }

    public render(): JSX.Element {
        let type = 'text';
        const { label } = this.props;
        if (this.props.useDatePicker) {
            type = 'date';
        }
        return (
            <div className='field-group'>
                <input
                    placeholder={this.props.placeholder}
                    className={classnames(
                        'field-control',
                        label?.toLowerCase(),
                        this._internalValue.length > 0 && 'field-control--value',
                        this._errorMessage.length > 0 && 'field-control--error'
                    )}
                    type={type}
                    onChange={this._valueChanged}
                    value={this._internalValue}
                    onBlur={this._blurred}
                    maxLength={this.props.maxLength}
                    {...this.props.htmlProps}
                />
                <label className='field-label'>{this.props.label}</label>
            </div>
        );
    }

    private _blurred(event: React.ChangeEvent<HTMLInputElement>): void {
        this._isFirstBlur = false;
        this._valueChanged(event);
    }

    private _valueChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        this._internalValue = event.target.value;
        const currentlyInvalid = this.props.isValid(this._internalValue, this.props.id);
        const isValid = currentlyInvalid ? false : true;

        // we only want to show an error if a user has clicked away and its
        // still wrong
        if (currentlyInvalid && !this._isFirstBlur) {
            this._errorMessage = currentlyInvalid;
        } else {
            this._errorMessage = '';
        }

        this.props.onChange(this._internalValue, isValid, this.props.id);
    }
}
